<template>
  <div
    v-if="props.footerLinks?.length"
    class="columns-2 lg:columns-3"
  >
    <template v-for="(link, index) in props.footerLinks">
      <NuxtLink
        v-if="link.action"
        :key="index"
        no-prefetch
        class="block cursor-pointer text-sm font-normal leading-10 text-white hover:underline focus:underline"
        role="button"
        @click="link.action()"
      >
        {{ link.title }}
      </NuxtLink>
      <NuxtLink
        v-else
        :key="`p${index}`"
        :target="link?.target ?? '_self'"
        no-prefetch
        class="block text-sm font-normal leading-10 text-white hover:underline focus:underline"
        :to="link.link"
      >
        {{ link.title }}
      </NuxtLink>
    </template>
  </div>
</template>

<script setup lang="ts">
interface LinkInterface {
  active?: number | boolean
  current?: number | boolean
  hasSubpages?: number | boolean
  link: string
  spacer?: number | boolean
  target?: string
  title: string
  action?: () => void
}
const props = defineProps<{
  footerLinks: Array<LinkInterface>
}>()
</script>
